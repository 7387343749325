<template>
    <section>
        <login-component v-if="view == 1" v-on:submit="handleCodeSubmission" />
        <invoice-component v-if="view == 2" v-on:proceed="handleProceed" />
        <kiosk-component v-if="view == 3" v-on:close="handleClose" />
        <success-component v-if="view == 4" v-on:close="handleClose" />
    </section>
</template>

<script setup>
import LoginComponent from '@/components/Booking/LoginComponent.vue';
import InvoiceComponent from '@/components/Booking/InvoiceComponent.vue';
import KioskComponent from '@/components/Booking/KioskComponent.vue';
import SuccessComponent from '@/components/Booking/SuccessComponent.vue';

import { computed } from 'vue';
import { useStore } from 'vuex';

const view = computed(() => store.state.view);
const store = useStore();

/**
 * The handler when LoginComponent already receives the intended booking code and surname.
 * This will try to load the booking from the server.
 */
 function handleCodeSubmission(payload) {
  store.commit('setLoading', {
    show: true,
    title: 'Retrieving Booking..',
    subtitle: 'Please wait while we retrieve the booking details.'
  });
  store.dispatch('get', {
    code: payload.code,
    surname: payload.surname
  }).then(() => {
    store.commit('setView', 2);
  }).catch((err) => {
    if (err.response) {
      const data = err.response.data;
      if (err.response.status == 400) {
        data.data.errors.forEach((error) => {
          store.commit('setError', {
            show: true,
            message: error.message
          });
        });
      }
      if (err.response.status == 404) {
        store.commit('setError', {
          show: true,
          message: 'The booking code with ' + payload.code + ' under the surname ' + payload.surname + ' was not found.'
        });
      }
    } else {
      store.commit('setError', {
        show: true,
        message: 'There has been an error while trying to connect to the servers. Please try again later.'
      });
    }
  }).finally(() => {
    store.commit('setLoading', {
      show: false,
      title: '',
      subtitle: ''
    });
  })
}

/**
 * The handler when the client proceed to pay from the InvoiceComponent. This will be called
 * then the Invoicecomponent will pass in the invoice number of the booking.
 *
 * @param invoiceNumber
 */
function handleProceed(invoiceNumber) {
  store.commit('setLoading', {
    show: true,
    title: 'Retrieving Payment Modes.',
    subtitle: 'Please wait while we retrieve the available payment modes.'
  });
  store.dispatch('initialize', invoiceNumber).then(() => {
    store.commit('setView', 3);
  }).catch((err) => {
    if (err.response) {
      const data = err.response.data;
      if (err.response.status == 400) {
        data.data.errors.forEach((error) => {
          store.commit('setError', {
            show: true,
            message: error.message
          });
        });
      }
      if (err.response.status == 404) {
        store.commit('setError', {
          show: true,
          message: 'The booking to be paid was not found. Please contact your agent or try to book again.'
        });
      }
    } else {
      store.commit('setError', {
        show: true,
        message: 'There has been an error while trying to connect to the servers. Please try again later.'
      });
    }
    store.commit('setView', 2);
  })
}

/**
 * The handler when the client clicks the Back to Booking button from the
 * KioskComponent or the SuccessComponent. Sends the client back to the invoice screen.
 */
function handleClose() {
  store.commit('setView', 1);
}
</script>

<style></style>