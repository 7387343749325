
<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container class="payment-container flex items-center justify-center q-pa-xs">
      <q-card class="main" flat>
        <q-card-section>
          <div class="text-center q-mt-lg">
            <img src="/logo.png" style="width: 150px"/>
          </div>
        </q-card-section>
        <q-card-section v-show="!loading.show">
          <booking-payment-component v-if="type == 1"/>
          <hub-subscription-payment-component v-on:error="type = 1" v-else/>
        </q-card-section>
        <q-card-section v-show="loading.show">
          <div class="row justify-center items-center" style="height: 100%; width: 100%;">
            <div class="q-pa-sm column">
              <div class="text-center q-py-lg">
                <q-spinner
                color="primary"
                size="4em"
                :thickness="5"
                />
              </div>
              <span class="text-h5 text-center text-grey-6 q-py-xs">{{ loading.title }}</span>
              <span class="text-subtitle1 text-center">{{ loading.subtitle }}</span>
            </div>
          </div>
        </q-card-section>
        <q-card-section v-if="error.show">
          <q-banner class="bg-negative text-white">
            <template v-slot:avatar>
              <q-icon name="error_outline" color="white" />
            </template>
            {{ error.message }}
            <template v-slot:action>
              <q-btn flat color="white" label="Confirm" @click="error.show = false"/>
            </template>
          </q-banner>
        </q-card-section>
      </q-card>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import BookingPaymentComponent from './components/BookingPaymentComponent.vue';
import HubSubscriptionPaymentComponent from './components/HubSubscriptionPaymentComponent.vue'

import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const error = computed(() => store.state.error);
const loading = computed(() => store.state.loading);

// Set the payment type to 1 which resolves to payment for booking else
// it is a payment for hub subscription fee.
const type = ref(1);

// Let's determine which interface to show by determining the kind of payload
// given from the URL params.
const url = window.location.href;

// Create a URL object
const urlObj = new URL(url);

// Use URLSearchParams to parse the query string
const params = new URLSearchParams(urlObj.search);

// Access query parameters
const hubSubscriptionFeeId = params.get('id');

// If there is no id (which resolves to hub sub fee) load the default
// UI for booking and/or extension payment.
if (hubSubscriptionFeeId) {
  type.value = 2;
} else {
  type.value = 1;
}

</script>
<style>
.payment-container {
  height: 80vh;
  min-width: 350px;
}

.payment-container .main {
  min-width: 350px;
  width: 800px;
}
</style>
