<template>
  <section>
    <q-card flat>
      <q-card-section>
        <div class="column justify-between" style="min-height: 200px; height: 600px">
          <div>
            <q-tab-panels v-model="tab" animated>
              <q-tab-panel name="billing" v-if="data">
                <div class="q-py-md">
                  <span class="text-h5">Hi {{ data.operator_name }}!</span><br>
                  <span class="text-h6 text-grey-7">Here are your vehicle's hub subscription fee billing for {{
                    data.label_month
                    }}!</span>
                </div>
                <q-list bordered separator>
                  <q-item>
                    <q-item-section>
                      <q-item-label>{{ data.vehicle_name }}</q-item-label>
                      <q-item-label caption>Vehicle</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label>{{ data.plate_number }}</q-item-label>
                      <q-item-label caption>Plate Number</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label>{{ data.yard_name }}</q-item-label>
                      <q-item-label caption>Hub</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label>{{ data.label_month }}</q-item-label>
                      <q-item-label caption>Billing Month and Year</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
                <q-list class="q-mt-md" bordered separator>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="text-positive">{{ data.label_amount }}</q-item-label>
                      <q-item-label caption>Hub Subscription Fee</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="text-positive">+ {{ data.label_payment_gateway_fee }}</q-item-label>
                      <q-item-label caption>Convenience Fee</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="text-positive">{{ data.label_total_amount }}</q-item-label>
                      <q-item-label caption>Total Amount</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
                <div class="q-py-md">
                  <q-btn style="width: 100%" :label="btnPayLabel" rounded size="lg" color="primary"
                    @click="tab = 'payment'" />
                </div>
              </q-tab-panel>
              <q-tab-panel name="payment">
                <h5>Please select your payment method</h5>
                <q-list bordered separator>
                  <q-item clickable v-ripple @click="showCardInput">
                    <q-item-section avatar>
                      <img src="/payment-card.png" style="width: 150px">
                    </q-item-section>
                    <q-item-section>
                      <q-item-label class="text-h6">Card Payment</q-item-label>
                      <q-item-label caption>Pay Hub Subscription Fee <span class="text-positive">{{ hubSubscriptionFee
                          }}</span> using
                        Visa/Mastercard.</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item clickable v-ripple @click="getGcashAuthenticationUrl">
                    <q-item-section avatar>
                      <img src="/payment-gcash.webp" style="width: 150px">
                    </q-item-section>
                    <q-item-section>
                      <q-item-label class="text-h6">GCash</q-item-label>
                      <q-item-label caption>Pay Hub Subscription Fee <span class="text-positive">{{ hubSubscriptionFee
                          }}</span> using your
                        GCash
                        e-wallet.</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
                <div class="q-py-md">
                  <q-btn style="width: 100%" color="black" label="Back to Billing Details" rounded size="lg" glossy
                    @click="tab = 'billing'" />
                </div>
              </q-tab-panel>
              <q-tab-panel name="finalize">
                <iframe :src="authUrl" width="100%" height="400" frameborder="0" v-if="authUrl">
                </iframe>
                <card-input-component :amount="hubSubscriptionFee" v-if="paymentMethod == 'card' && !authUrl"
                  v-on:submit="handleCardSubmission" v-on:cancel="tab = 'payment'" />
                <!-- Finalize Payment content goes here -->
              </q-tab-panel>
              <q-tab-panel name="success">
                <div class="column text-center">
                  <span class="text-h6"> <q-icon name="check_circle" class="text-positive" size="sm" /> You have
                    successfully paid <span class="text-positive">{{ hubSubscriptionFee }}</span> of Hub Subscription Fee.</span>
                    <br><br>
                  <span class="text-subtitle1 text-grey-6">
                    We will be sending the payment confirmation to your registered email. <br>You may now close this window.
                  </span>
                </div>
              </q-tab-panel>
            </q-tab-panels>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </section>
</template>

<script setup>
import { useStore } from 'vuex';
import { defineEmits, ref } from 'vue';
import CardInputComponent from './Common/CardInputComponent.vue';
//import constants from '@/const'

const store = useStore();
const hubSubscriptionFee = ref(0);
const tab = ref('billing');
const btnPayLabel = ref('Pay');
const paymentMethodId = ref();
const data = ref();

// The auth URL that the iframe will use for auth purposes.
const authUrl = ref(null);

// The default payment method is gcash if they just click Next
// without selecting a method.
const paymentMethod = ref('gcash');

const emits = defineEmits(['error'])

// Get the full URL
const url = window.location.href;

// Create a URL object
const urlObj = new URL(url);

// Use URLSearchParams to parse the query string
const params = new URLSearchParams(urlObj.search);

// Access query parameters
const id = params.get('id');
const surname = params.get('surname');

function getGcashAuthenticationUrl() {
  paymentMethod.value = 'gcash';
  store.commit('setLoading', {
    show: true,
    title: 'Preparing..',
    subtitle: 'Please wait while we prepare to authenticate your transaction request.'
  });
  store.dispatch('submitGcash').then((response) => {
    if (response.status == 'success') {
      paymentMethodId.value = response.paymentMethodId;
      pay(id, response.paymentMethodId);
    }
  }).catch(() => {
    store.commit('setError', {
      show: true,
      message: 'There has been an error while trying to authenticate. Please try again later.'
    });
  })
}

function showCardInput() {
  paymentMethod.value = 'card';
  tab.value = 'finalize';
}

function pay(id, paymentMethodId) {
  store.commit('setLoading', {
    show: true,
    title: 'Processing Transaction..',
    subtitle: 'Do not close this window to avoid transaction errors.'
  });
  store.dispatch('hsf/pay', {
    id: id,
    payment_method_id: paymentMethodId
  }).catch(() => {
    emits('error', true);
  }).then((data) => {
    handlePaymentStatus(data);
  }).finally(() => {
    store.commit('setLoading', {
      show: false,
      title: '',
      subtitle: ''
    });
  })
}

function handlePaymentStatus(data) {
  switch (data.status) {
    case 'awaiting_next_action':
      tab.value = 'finalize';
      authUrl.value = data.data.url;
      break;
    case 'success':
      tab.value = 'billing';
      break;
    default:
      tab.value = 'success';
      break;
  }
}

function handleCardSubmission(pm) {
  paymentMethodId.value = pm;
  pay(id, paymentMethodId.value);
}

//--------------------------- Main ------------------------
window.addEventListener('message', (ev) => {
  if (ev.data === '3DS-authentication-complete') {
    authUrl.value = null;
    pay(id, paymentMethodId.value);
  }
});

store.dispatch('hsf/load', {
  id: id,
  surname: surname
}).catch(() => {
  emits('error', true);
}).then((response) => {
  handlePaymentStatus(response);
  hubSubscriptionFee.value = store.state.hsf.data.label_total_amount;
  data.value = store.state.hsf.data;
  btnPayLabel.value = 'Pay for ' + hubSubscriptionFee.value;
})
</script>

<style></style>