import axios from 'axios'

/**
 * Store used for Hub Subscription Fee.
 */
const hsf = {
    state: () => ({
        data: null,
        view: 1,
    }),
    actions: {
        load({
            commit
        }, payload) {
            commit('setLoading', {
                show: true,
                title: 'Loading vehicle',
                subtitle: 'Please wait while we load your vehicle details..'
            }, {
                root: true
            })
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    baseURL: '', // To prevent axios from adding a forward / that produces 301
                    url: process.env.VUE_APP_API_HSF + '?id=' + payload.id + '&surname=' + payload.surname
                }).then((response) => {
                    commit('setData', response.data.data);
                    return resolve(response.data);
                }).catch((err) => {
                    if (err.response) {
                        if (err.response.status === 404) {
                            commit('setError', {
                                show: true,
                                message: 'The hub subscription billing was not found. Please try again.',
                            }, {
                                root: true
                            })
                        } else {
                            console.error(err);
                        }
                    }

                    return reject(err);
                }).finally(() => {
                    commit('setLoading', {
                        show: false,
                        title: '',
                        subtitle: ''
                    }, {
                        root: true
                    })
                })
            });
        },
        pay({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    baseURL: '', // To prevent axios from adding a forward / that produces 301
                    url: process.env.VUE_APP_API_HSF + '/pay',
                    data: {
                        id: payload.id,
                        payment_method_id: payload.payment_method_id
                    }
                }).then((response) => {
                    return resolve(response.data);
                }).catch((err) => {
                    return reject(err);
                }).finally(() => {
                    commit('setLoading', {
                        show: false,
                        title: '',
                        subtitle: ''
                    }, {
                        root: true
                    })
                })
            });
        }
    },
    mutations: {
        setData(state, data) {
            state.data = data;
        }
    },
    namespaced: true,
}

export default hsf;