<template>
    <section>
        <div class="row">
            <div class="col-12 col-md-6 q-pa-xs">
                <q-input 
                    filled 
                    v-model="code.value" 
                    label="Booking Code"
                    mask="A#A#A#A#"
                    :error="code.error != null"
                    :error-message="code.error"
                />
            </div>
            <div class="col-12 col-md-6 q-pa-xs">
                <q-input 
                    filled 
                    v-model="surname.value" 
                    label="Surname"
                    :error="surname.error != null"
                    :error-message="surname.error"
                />
            </div>
        </div>
        <div class="flex justify-center q-mt-xl q-mb-md">
            <q-btn color="primary" style="width: 200px" size="lg" @click="submit" label="Submit"/>
        </div>
    </section>
</template>

<script setup>
import { ref, defineEmits } from 'vue'

const emits = defineEmits(['submit'])

const code = ref({
    value: '',
    error: null
});

const surname = ref({
    value: '',
    error: null
});

// Get the full URL
const url = window.location.href;

// Create a URL object
const urlObj = new URL(url);

// Use URLSearchParams to parse the query string
const params = new URLSearchParams(urlObj.search);

// Access query parameters
const bookingCode = params.get('booking_code');
const bookingSurname = params.get('surname');
const auth = params.get('auth_only');

if (auth) {
    window.parent.postMessage('3DS-authentication-complete', '*');
}

// Use the values
if (bookingCode && bookingSurname) {
    surname.value.value = bookingSurname;
    code.value.value = bookingCode;

    emits('submit', {
        code: code.value.value,
        surname: surname.value.value
    });
}

function submit() {
    var hasError = false;

    code.value.error = null;
    surname.value.error = null;

    if (code.value.value == '') {
        hasError = true;
        code.value.error = 'The booking code is required.';
    }

    if (surname.value.value == '') {
        hasError = true;
        surname.value.error = 'Please enter the surname of the booking owner.';
    }

    if (hasError) {
        return;
    }

    emits('submit', {
        code: code.value.value,
        surname: surname.value.value
    });
}
</script>

<style>

</style>